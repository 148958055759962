import { Heading } from '@/components/commonComponents/heading/heading';
import { Container } from '@/components/pageComponents/container/container';
import useMediaQuery from 'hooks/useMediaQuery';
import { ReactElement, useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import { IconButton } from '../../commonComponents/iconButton/iconButton';
import { AbstractButton } from '../../internalComponents/abstractButton/abstractButton';
import { TextBlock } from '../textBlock/textBlock';
import styles from './carousel.module.scss';

interface CarouselProps {
  title?: string;
  copy?: string;
  copyLocation?: 'top' | 'bottom';
  showControls?: boolean;
  fadeInactive?: boolean;
  items: ReactElement[];
}

type SwiperClass = ReturnType<typeof useSwiper>;

export const Carousel = ({
  title,
  copy,
  copyLocation = 'top',
  showControls,
  fadeInactive,
  items,
}: CarouselProps) => {
  const [activeItem, setActiveItem] = useState(0);
  const [swiper, setSwiperInstance] = useState<SwiperClass | undefined>(
    undefined
  );
  const matchesMobile = useMediaQuery('(max-width: 550px)');

  return (
    <>
      {(title || (copy && copyLocation === 'top')) &&
        (copy && copyLocation === 'top' ? (
          <TextBlock title={title} copy={copy} paragraphStyle="large" />
        ) : (
          <Container size="narrow">
            <Heading level="h2" style="regular" tag="h2">
              {title}
            </Heading>
          </Container>
        ))}
      <div className={styles.carousel}>
        <div className={styles.slidesContainer}>
          <div className={styles.slides}>
            <Swiper
              slidesPerView="auto"
              centeredSlides
              loop
              onSlideChange={(slider) => {
                setActiveItem(slider.realIndex);
              }}
              onSwiper={(slider) => setSwiperInstance(slider)}
            >
              {items.map((item, index) => (
                <SwiperSlide
                  key={item.key}
                  className={`${styles.slide} ${
                    index !== activeItem && fadeInactive ? styles.faded : ''
                  }`}
                >
                  {item}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {showControls && (
            <>
              <IconButton
                className={`${styles.directionButton} ${styles.prevButton}`}
                icon="ArrowLeft"
                style="secondary"
                surface="inverted"
                shape="circle"
                onClick={() => swiper?.slidePrev()}
                aria-label="Vorige slide"
              />
              <IconButton
                className={`${styles.directionButton} ${styles.nextButton}`}
                icon="ArrowRight"
                style="secondary"
                surface="inverted"
                shape="circle"
                onClick={() => swiper?.slideNext()}
                aria-label="Volgende slide"
              />
            </>
          )}
        </div>
        {showControls && !matchesMobile && (
          <div className={styles.controls}>
            <nav className={styles.controlButtons}>
              {items.map((item, index) => (
                <AbstractButton
                  key={item.key}
                  className={`${styles.controlButton} ${
                    index === activeItem ? styles.active : ''
                  }`}
                  onClick={() => swiper?.slideTo(index)}
                  aria-label={`Naar slide ${index + 1}`}
                />
              ))}
            </nav>
          </div>
        )}
      </div>
      {copy && copyLocation === 'bottom' && <TextBlock copy={copy} />}
    </>
  );
};
