import { AbstractButton } from '../abstractButton/abstractButton';
import { Icon } from '../icon';
import styles from './growingArrowButton.module.scss';

interface GrowingArrowButtonProps {
  className?: string;
  onClick?: () => void;
  href?: string;
  hover?: boolean;
}

export const GrowingArrowButton = ({
  className,
  href,
  onClick,
  hover,
}: GrowingArrowButtonProps) => {
  return !href && !onClick ? (
    <span
      className={`${styles.growingArrowButton} ${hover ? styles.active : ''} ${
        className ?? ''
      }`}
    >
      <Icon name="LongArrow" className={styles.growingArrowIcon} />
    </span>
  ) : (
    <AbstractButton
      href={href}
      onClick={onClick}
      className={`${styles.growingArrowButton} ${className ?? ''}`}
    >
      <Icon name="LongArrow" className={styles.growingArrowIcon} />
    </AbstractButton>
  );
};
