import { fetchMultipleEntries } from '@/helpers/api/fetchMultipleEntries';

import { PostV2 } from '@/types/post';
import { GetReqMultiple } from '@/types/getRequests';

interface GetPostsReturn {
  pageCount: number;
  data: PostV2[];
  strapiResponseVersion?: string;
}

export const getPostsV2 = async (
  options: GetReqMultiple
): Promise<GetPostsReturn> => {
  return fetchMultipleEntries<GetPostsReturn>({
    ...options,
    strapiResponseVersion: '2',
    collectionType: 'posts',
  });
};
