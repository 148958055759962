import Image from 'next/image';

import { Author } from '../../author/author';
import styles from './styles.module.scss';

export interface TestimonialProps {
  quote: string;
  name: string;
  jobTitle?: string;
  company: string;
  photo?: string;
  logo?: string;
  quoteColor?: string;
}

export const Testimonial = ({
  quote,
  name,
  jobTitle,
  company,
  photo,
  logo,
  quoteColor = '#C0C0C0',
}: TestimonialProps) => {
  return (
    <div
      className={`${styles.testimonial} ${logo ? styles.logo : styles.noLogo}`}
    >
      {logo ? (
        <span className={styles.company}>
          <Image src={logo} alt={company} className={styles.companyLogo} fill />
        </span>
      ) : (
        <span className={styles.icon} style={{ color: quoteColor }} />
      )}
      <blockquote className={styles.quote}>{quote}</blockquote>
      <Author
        className={styles.author}
        style="simple"
        title={name}
        subtitle={`${jobTitle ? `${jobTitle} @ ` : ''}${company}`}
        authors={photo ? [{ name, image: photo }] : []}
      />
    </div>
  );
};
