'use client';
import { Container } from 'components/pageComponents/container/container';
import { Typography } from 'components/typography';

import styles from './styles.module.scss';

type ContactFormSectionProps = {
  eyebrow: string;
  heading: string;
  bodyCopy: string | React.ReactNode;
  form: React.ReactElement<HTMLFormElement>;
};

export const ContactFormSection = ({
  eyebrow,
  heading,
  bodyCopy,
  form,
}: ContactFormSectionProps) => {
  return (
    <Container
      id="contact-form-section"
      size="xWide"
      className={styles.container}
    >
      <div className={styles.textBlock}>
        <div className={styles.header}>
          <Typography variant="heading2-sub" tag="h2">
            {eyebrow}
          </Typography>
          <Typography variant="heading2-compact" tag="h3">
            {heading}
          </Typography>
        </div>

        {bodyCopy && (
          <Typography variant="paragraph-reg" tag="p">
            {bodyCopy}
          </Typography>
        )}
      </div>
      {form}
    </Container>
  );
};
