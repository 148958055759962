'use client';
import Image from 'next/image';

import { LinkButton } from 'components/buttons';

import { Heading } from '../../commonComponents/heading/heading';
import { Container } from '../../pageComponents/container/container';
import styles from './contactCapi.module.scss';

export interface ContactCapiProps {
  title: string;
  capi: {
    name: string;
    email: string;
    phone: string;
    image: string;
  };
}

export const ContactCapi = ({ title, capi }: ContactCapiProps) => {
  const formatPhone = () => {
    return capi.phone.replace(/(\d{2})(\d{3})(\d{3})(\d{2})/, '$1 - $2 $3 $4');
  };

  return (
    <>
      <Container size="narrow">
        <Heading style="regular" level="h2" tag="h2">
          {title}
        </Heading>
      </Container>
      <Container size="wide" layoutBlock="list" className={styles.contactCapi}>
        <div className={styles.image}>
          <Image src={capi.image} alt={capi.name} fill />
        </div>
        <div className={styles.contactInfo}>
          <Heading level="h3" style="compact" tag="h2" className={styles.intro}>
            {`Wacht niet langer, neem contact op met ${capi.name}!`}
          </Heading>
          <LinkButton
            style={{ type: 'primary' }}
            href={`mailto:${capi.email}`}
            icon={{ leading: 'Email' }}
          >
            {capi.email}
          </LinkButton>
          <LinkButton
            style={{ type: 'primary' }}
            href={`tel:31${capi.phone.substring(1)}`}
            icon={{ leading: 'Phone' }}
          >
            {formatPhone()}
          </LinkButton>
          <LinkButton
            style={{ type: 'primary' }}
            href={`https://wa.me/31${capi.phone.substring(1)}`}
            icon={{ leading: 'WhatsApp' }}
          >
            Stuur mij een appje!
          </LinkButton>
        </div>
      </Container>
    </>
  );
};
