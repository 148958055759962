import Image from 'next/image';

import { Heading } from '../heading/heading';
import { Paragraph } from '../paragraph/paragraph';
import styles from './author.module.scss';
import { NamedImageType } from '@/types/misc';

export interface AuthorProps {
  style: 'fancy' | 'simple';
  title: string;
  subtitle?: string;
  authors: NamedImageType[];
  className?: string;
}

export const Author = ({
  style,
  title,
  subtitle,
  authors,
  className,
}: AuthorProps) => (
  <div
    className={`${styles.author} ${styles[style]} ${
      className ? className : ''
    }`}
  >
    {style === 'fancy' ? (
      <>
        <Heading className={styles.title} style="sub" level="h2" tag="p">
          {title}
        </Heading>
        <div className={styles.images}>
          {authors.map((author) => (
            <span key={author.name} className={styles.imageContainer}>
              <Image
                src={author.image}
                alt={author.name}
                title={author.name}
                className={styles.image}
                fill
              />
            </span>
          ))}
        </div>
      </>
    ) : (
      <>
        {authors[0] && (
          <span className={styles.imageContainer}>
            <Image
              src={authors[0].image}
              alt={authors[0].name}
              title={authors[0].name}
              className={styles.image}
              fill
            />
          </span>
        )}
        <div>
          <Paragraph style="large">{title}</Paragraph>
          <Paragraph className={styles.subtitle} style="large">
            {subtitle ?? ''}
          </Paragraph>
        </div>
      </>
    )}
  </div>
);
