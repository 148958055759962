import { api } from './apiBaseUrl';

import { GetReqMultiple } from '@/types/getRequests';
import { compressToEncodedURIComponent } from 'lz-string';

export const fetchMultipleEntries = async <T>({
  collectionType,
  locale = 'nl',
  params,
  strapiResponseVersion = '1',
}: { collectionType: string } & GetReqMultiple): Promise<T> => {
  const queryObj = {
    ...params,
    locale,
  };
  const compressedQuery = compressToEncodedURIComponent(
    JSON.stringify(queryObj)
  );

  try {
    const { data: strapiData } = await api.get(
      `/${collectionType}?cq=${compressedQuery}`,
      strapiResponseVersion
    );

    return {
      data: strapiData.data,
      pageCount:
        strapiResponseVersion === '1'
          ? strapiData.pagination.pageCount
          : strapiData.meta.pagination.pageCount,
    } as T;
  } catch (error) {
    throw new Error(`Failed to fetch data from ${collectionType}: ${error}`);
  }
};
