'use client';
import { Heading } from '@/components/commonComponents/heading/heading';

import { PrimaryButton } from 'components/buttons';

import { JobListItem } from '../../internalComponents/jobListItem/jobListItem';
import { Container } from '../../pageComponents/container/container';
import { TextBlock } from '../textBlock/textBlock';
import styles from './vacancyList.module.scss';
import { ButtonType } from '@/types/misc';
import { Vacancy } from '@/types/vacancy';

export interface VacancyListProps extends ButtonType {
  title?: string;
  copy?: string;
  vacancies: Vacancy[];
  layoutType: 'row' | 'column';
  showDescription?: boolean;
}

export const VacancyList = ({
  title,
  copy,
  vacancies,
  layoutType,
  showDescription = true,
  buttonLabel,
  buttonLink,
}: VacancyListProps) => {
  return (
    <>
      {(title || copy) &&
        (copy ? (
          <Container size={layoutType === 'column' ? 'xWide' : 'narrow'}>
            <TextBlock
              title={title}
              copy={copy}
              style={layoutType}
              contained={false}
              {...(layoutType === 'column' ? { buttonLabel, buttonLink } : {})}
            />
          </Container>
        ) : (
          <Container size="narrow">
            <Heading level="h2" style="regular" tag="h2">
              {title}
            </Heading>
          </Container>
        ))}
      <Container
        className={`${styles.vacancyList} ${styles.noRowGap}`}
        layoutBlock={layoutType === 'column' ? 'jobs' : undefined}
        size={showDescription ? 'xWide' : 'wide'}
      >
        {vacancies.map((vacancy) => {
          return (
            <JobListItem
              key={vacancy.id}
              {...vacancy}
              description={showDescription ? vacancy.description : undefined}
              layoutType={layoutType}
            />
          );
        })}
      </Container>
      {buttonLabel && buttonLink && layoutType !== 'column' && (
        <Container size="narrow" layoutBlock="centered">
          <PrimaryButton href={buttonLink}>{buttonLabel}</PrimaryButton>
        </Container>
      )}
    </>
  );
};
