'use client';
import { Heading } from '@/components/commonComponents/heading/heading';
import { useMemo, useState } from 'react';

import { PrimaryButton } from 'components/buttons';

import { Filters } from '../../internalComponents/filters/filters';
import { Container } from '../../pageComponents/container/container';
import { TextBlock } from '../textBlock/textBlock';
import { VacancyList } from '../vacancyList/vacancyList';
import { ButtonType, FilterType, SelectedFilter } from '@/types/misc';
import { Vacancy } from '@/types/vacancy';

export interface VacanciesProps extends ButtonType {
  title?: string;
  copy?: string;
  filters?: FilterType[];
  tiles: Vacancy[];
  footer?: string;
}

export const Vacancies = ({
  title,
  copy,
  filters,
  tiles,
  footer,
  buttonLabel,
  buttonLink,
}: VacanciesProps) => {
  const initialFilter = {
    key: filters?.[0].field ?? '',
    value: 'alle',
  };
  const [currentFilter, setCurrentFilter] =
    useState<SelectedFilter>(initialFilter);

  const selectedTiles = useMemo(() => {
    const filterKey = currentFilter.key === 'basis' ? 'fte' : 'type';
    if (currentFilter.value === 'alle') {
      return tiles;
    }

    return tiles.filter(
      (tile) =>
        `${tile[filterKey as keyof Vacancy]}`.includes(currentFilter.value) ||
        currentFilter.value.includes(`${tile[filterKey as keyof Vacancy]}`)
    );
  }, [tiles, currentFilter]);

  return (
    <>
      {(title || copy) &&
        (copy ? (
          <TextBlock title={title} copy={copy} />
        ) : (
          <Container size="narrow">
            <Heading level="h2" style="regular" tag="h2">
              {title}
            </Heading>
          </Container>
        ))}
      {filters && filters.length > 0 && (
        <Container size="wide">
          <Filters
            filters={filters}
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
          />
        </Container>
      )}

      <VacancyList vacancies={selectedTiles} layoutType="row" />
      {(footer || (buttonLabel && buttonLink)) && (
        <Container size="narrow" layoutBlock="left">
          {footer && (
            <Heading level="h2" style="regular" tag="h3">
              {footer}
            </Heading>
          )}
          {buttonLabel && buttonLink && (
            <PrimaryButton href={buttonLink}>{buttonLabel}</PrimaryButton>
          )}
        </Container>
      )}
    </>
  );
};
