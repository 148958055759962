import { Paragraph } from '@/components/commonComponents/paragraph/paragraph';
import styles from './loader.module.scss';

export function Loader() {
  return (
    <span>
      <div className={styles.loader}>
        <svg viewBox="0 0 80 80">
          <rect x="8" y="8" width="64" height="64"></rect>
        </svg>
      </div>
      <Paragraph style="large" contained={false}>
        Even wat ophalen...
      </Paragraph>
    </span>
  );
}
