import { Icon } from '@/components/internalComponents/icon';
import classNames from 'classnames';

import { Typography } from 'components/typography';

import styles from './styles.module.scss';

export type ClientCardProps = {
  name: string;
  jobTitle: string;
  img: string;
  page?: string;
};

export function ClientCard({ name, jobTitle, img, page }: ClientCardProps) {
  const Tag = page ? 'a' : 'div';
  return (
    <Tag
      className={classNames(styles.card, page && styles.hasPage)}
      href={page}
    >
      <img src={img} alt={name} title={name} className={styles.logo} />
      <div className={styles.footer}>
        <Typography tag="p" variant="label-small" className={styles.name}>
          {name}
        </Typography>
        <Typography
          tag="p"
          variant="label-small"
          color="muted"
          className={styles.jobTitle}
        >
          {jobTitle}
        </Typography>
        {page && <Icon name="ArrowRight" className={styles.arrow} />}
      </div>
    </Tag>
  );
}
