'use client';
import { Button } from '@/components/commonComponents/button/button';
import { Heading } from '@/components/commonComponents/heading/heading';
import { Paragraph } from '@/components/commonComponents/paragraph/paragraph';
import { Container } from '@/components/pageComponents/container/container';
import sanitize from 'sanitize-html';

import styles from './mediaEmbed.module.scss';
import { MediaTextBlock } from '@/components/selfContainedComponents/mediaEmbed/mediaTextBlock';
import { MediaItem } from '@/components/selfContainedComponents/mediaEmbed/mediaItem';
import { MediaEmbedServerProps } from '@/components/selfContainedComponents/mediaEmbed/mediaEmbedServer';
import { SingleMediaResponse } from '@/types/strapiBasicResponseProps';

export interface MediaEmbedProps extends Partial<MediaEmbedServerProps> {
  className?: string;
  media: SingleMediaResponse | any;
}

export const MediaEmbed = ({
  className,
  mediaType = 'landscape',
  underlay = false,
  title,
  copy,
  media,
  thumbnail,
  sourceLink,
  buttonLabel,
  buttonLink,
  direction: strapiDirection,
}: MediaEmbedProps) => {
  const direction = !strapiDirection ? 'rtl' : strapiDirection;

  const containerSize = () => {
    if (mediaType === 'landscape' && title && copy) return 'xWide';
    return 'wide';
  };

  const containerLayout = () => {
    if (mediaType === 'spotify') return 'cast';
    if (title && copy && mediaType === 'landscape') return 'media';
    if ((title || copy) && mediaType === 'fit') return 'list';
    return undefined;
  };

  return (
    <Container size={containerSize()} layoutBlock={containerLayout()}>
      {['landscape', 'fit'].includes(mediaType) &&
        (title || copy) &&
        direction === 'rtl' && (
          <MediaTextBlock
            title={title}
            copy={copy}
            buttonLabel={buttonLabel}
            buttonLink={buttonLink?.data?.attributes.slug}
          />
        )}
      <div
        className={`${styles.mediaEmbed} ${styles[mediaType]} ${
          underlay ? styles.underlay : ''
        } ${className ?? ''}`}
      >
        {mediaType === 'spotify' ? (
          <iframe
            className={styles.mediaSpotify}
            title={sanitize(`${title}`, { allowedTags: [] })}
            src={`https://open.spotify.com/embed/episode/${sourceLink}?theme=0`}
            allowFullScreen={false}
            allow="clipboard-write; encrypted-media"
          />
        ) : (
          <>
            {underlay && thumbnail && (
              <div
                className={styles.underlayment}
                style={{
                  backgroundImage: `url(${thumbnail.data.attributes.url})`,
                }}
              />
            )}
            {'data' in media && (
              <MediaItem
                sourceLink={sourceLink}
                mediaType={mediaType}
                media={media ?? thumbnail}
                thumbnail={thumbnail}
              />
            )}
            {mediaType === 'portrait' && (copy || buttonLabel) && (
              <div className={styles.content}>
                {copy && <Paragraph style="large">{copy}</Paragraph>}

                {buttonLabel && buttonLink?.data && (
                  <Button
                    style="underline"
                    icon="ArrowNorthEast"
                    href={buttonLink?.data?.attributes.slug}
                  >
                    {buttonLabel}
                  </Button>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {mediaType === 'spotify' && (
        <div className={styles.spotifyContent}>
          <Heading level="h3" style="regular" tag="h2">
            {title}
          </Heading>
          <Paragraph style="regular">{`${copy}`}</Paragraph>
        </div>
      )}
      {['landscape', 'fit'].includes(mediaType) &&
        (title || copy) &&
        direction === 'ltr' && (
          <MediaTextBlock
            title={title}
            copy={copy}
            buttonLabel={buttonLabel}
            buttonLink={buttonLink?.data?.attributes.slug}
          />
        )}
    </Container>
  );
};
