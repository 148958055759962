'use client';
import { TileList, TileListProps } from '../tileList/tileList';

export interface CasesProps extends TileListProps {
  itemsPerPage: number;
  pageCount: number;
  currentPage: number;
}

export const Cases = ({
  tiles: initialTiles,
  maxPerRow = 2,
  pageCount,
}: CasesProps) => {
  return <TileList tiles={initialTiles} maxPerRow={maxPerRow} />;
};
