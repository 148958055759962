import { useState } from 'react';

import { Paragraph } from '../../commonComponents/paragraph/paragraph';
import { AbstractButton } from '../abstractButton/abstractButton';
import { GrowingArrowButton } from '../growingArrowButton/growingArrowButton';
import { Icon } from '../icon';
import styles from './jobListItem.module.scss';

import { Vacancy } from '@/types/vacancy';

export interface JobListItemProps extends Vacancy {
  className?: string;
  layoutType: 'row' | 'column';
}

export const JobListItem = ({
  className,
  title,
  type,
  fte,
  description,
  layoutType,
  href,
}: JobListItemProps) => {
  const [hovering, setHovering] = useState<boolean>(false);

  return (
    <AbstractButton
      href={href}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <article
        className={`${styles.jobListItem} ${styles[layoutType]} ${
          !description ? styles.noDescription : ''
        } ${className ?? ''}`}
      >
        <h1 className={styles.title}>{title}</h1>
        <h2 className={styles.subtitle}>
          {type}
          {fte && ` · ${fte}`}
        </h2>
        {description && (
          <Paragraph className={styles.description} style="regular">
            {description}
          </Paragraph>
        )}
        <Icon className={styles.icon} name="ArrowRight" />
        <GrowingArrowButton className={styles.growingArrow} hover={hovering} />
      </article>
    </AbstractButton>
  );
};
