import classNames from 'classnames';
import Image from 'next/image';

import { LinkButton, LinkButtonProps } from 'components/link-button';
import { Typography } from 'components/typography';

import styles from './styles.module.scss';

type ContactImage = {
  src: string;
  alt: string;
};

export type ContactCardProps = {
  image?: ContactImage;
  heading: string;
  subHeading?: string;
  body?: string;
  actions: LinkButtonProps[];
  className?: string;
};

export const ContactCard = ({
  image,
  heading,
  subHeading,
  body,
  actions,
  className,
}: ContactCardProps) => {
  return (
    <div className={classNames(styles.contactCard, className)}>
      {image && (
        <div className={styles.imageWrapper}>
          <Image
            className={styles.image}
            src={image.src}
            alt={image.alt}
            width={220}
            height={322}
          />
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.heading}>
          {heading && (
            <Typography variant="label-reg" tag="h3" fontFamily="font-inter">
              {heading}
            </Typography>
          )}
          {subHeading && (
            <Typography
              variant="paragraph-reg"
              tag="h4"
              color="muted"
              fontFamily="font-inter"
            >
              {subHeading}
            </Typography>
          )}
        </div>
        {body && (
          <Typography variant="paragraph-reg" tag="p" className={styles.body}>
            {body.replace(/\\n/g, '\n')}
          </Typography>
        )}
        <div className={styles.actions}>
          {actions.map((action, index) => (
            <LinkButton variant="compact" key={index} {...action} />
          ))}
        </div>
      </div>
    </div>
  );
};
