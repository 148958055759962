'use client';
import { Icon } from '@/components/internalComponents/icon';
import classNames from 'classnames';
import { CSSProperties } from 'react';

import { Typography } from 'components/typography';

import { Testimonial as FallbackTestimonial } from './fallback';
import styles from './testimonial.module.scss';

export interface TestimonialProps {
  display: 'carousel' | 'grid';
  quote: string;
  name: string;
  jobTitle: string;
  company: string;
  logo: string;
  page?: string;
  quoteColor?: string;
  useFallback?: boolean;
}

export const Testimonial = ({
  display = 'carousel',
  quote,
  name,
  jobTitle,
  company,
  logo,
  page,
  quoteColor,
  useFallback = false,
}: TestimonialProps) => {
  const Tag = page ? 'a' : 'div';
  const labelSize = display === 'carousel' ? 'label-reg' : 'label-small';

  //TODO: remove this when new case page design is live
  if (useFallback) {
    return (
      <FallbackTestimonial
        quote={quote}
        name={name}
        jobTitle={jobTitle}
        company={company}
        quoteColor={quoteColor}
      />
    );
  }

  return (
    <Tag
      className={classNames(
        styles.testimonial,
        styles[display],
        page && styles.hasPage
      )}
      style={{ '--quote-color': `${quoteColor}33;` } as CSSProperties}
      href={page}
    >
      <img
        src={logo}
        alt={company}
        className={classNames(styles.logo, styles[display])}
      />

      <Typography
        variant={display === 'carousel' ? 'blockquote' : 'paragraph-small'}
        tag="blockquote"
        className={classNames(styles.quote, styles[display])}
      >
        {quote}
      </Typography>

      <div className={classNames(styles.author, styles[display])}>
        <Typography variant={labelSize} tag="p" className={styles.name}>
          {name}
        </Typography>
        {display === 'carousel' && (
          <Typography
            variant={labelSize}
            tag="p"
            className={classNames(styles.title, styles[display])}
          >
            {jobTitle}
          </Typography>
        )}
        <Typography
          variant={labelSize}
          tag="p"
          style={quoteColor ? { color: quoteColor } : {}}
          className={classNames(styles.company, styles[display])}
        >
          {company}
        </Typography>
        {page && <Icon name="ArrowRight" className={styles.arrow} />}
      </div>
    </Tag>
  );
};
