import { useEffect, useRef, useState } from 'react';

import { Button } from '../../commonComponents/button/button';
import styles from './filters.module.scss';
import { FilterType, OptionType, SelectedFilter } from '@/types/misc';

export interface FiltersProps {
  filters: FilterType[];
  currentFilter: SelectedFilter;
  setCurrentFilter?: (filter: SelectedFilter) => void;
  useHref?: boolean;
}

type FilterOption = OptionType['value'];

export const Filters = ({
  filters,
  currentFilter,
  setCurrentFilter,
}: FiltersProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [currentFilterType, setCurrentFilterType] = useState<FilterType>(
    filters.find((filter) => filter.field === currentFilter?.key) ?? filters[0]
  );

  const [currentOption, setCurrentOption] = useState<FilterOption | 'alle'>(
    currentFilterType.options.find(
      (option) => option.value === currentFilter?.value
    )?.value ?? 'alle'
  );

  const dropdown = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentFilter.value !== currentOption) {
      setCurrentOption(currentFilter.value);
    }
  }, [currentFilter, currentOption]);

  useEffect(() => {
    selectFilterOption('alle');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilterType]);

  const selectFilterOption = (option: FilterOption) => {
    setCurrentOption(option);
    setCurrentFilter?.({ key: currentFilterType.field, value: option });
  };

  useEffect(() => {
    if (!isOpen) return;

    function handleClick(event: MouseEvent) {
      if (
        dropdown.current &&
        !dropdown.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    window.addEventListener('mousedown', handleClick);
    return () => window.removeEventListener('mousedown', handleClick);
  }, [isOpen]);

  if (filters.length === 0) {
    return <></>;
  }

  return (
    <div
      className={`${styles.filters} ${
        filters.length > 1 ? styles.withDropdown : ''
      }`}
    >
      {filters.length > 1 && (
        <div className={styles.dropdown} ref={dropdown}>
          <Button
            className={`${styles.dropdownToggle} ${isOpen ? styles.open : ''}`}
            style="secondary"
            onClick={() => setIsOpen(!isOpen)}
            icon={isOpen ? 'ChevronUp' : 'ChevronDown'}
          >
            {`Filter op ${currentFilterType.label}`}
          </Button>
          {isOpen ? (
            <ul className={styles.dropdownList}>
              {filters.map((filter) => (
                <li key={filter.field} className={styles.dropdownItem}>
                  <Button
                    className={`${styles.dropdownButton} ${
                      filter.field === currentFilterType.field
                        ? styles.active
                        : ''
                    }`}
                    style="secondary"
                    surface={
                      filter.field === currentFilterType.field
                        ? 'neutral'
                        : 'inverted'
                    }
                    onClick={() => {
                      setCurrentFilterType(filter);
                      setIsOpen(false);
                    }}
                  >
                    {filter.label}
                  </Button>
                </li>
              ))}
            </ul>
          ) : (
            <></>
          )}
        </div>
      )}
      <div className={styles.optionsWrapper}>
        <div className={styles.options}>
          <Button
            style="secondary"
            surface={'alle' === currentOption ? 'neutral' : 'inverted'}
            onClick={() => selectFilterOption('alle')}
          >
            Alle
          </Button>
          {currentFilterType.options.map((option) => (
            <Button
              key={option.value}
              style="secondary"
              surface={option.value === currentOption ? 'neutral' : 'inverted'}
              onClick={() => selectFilterOption(option.value)}
            >
              {option.label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
