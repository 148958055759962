'use client';
import styles from './stats.module.scss';

type StatItem = {
  label: string;
  percentage: string;
};

export interface StatsProps {
  firstLabel: string;
  firstPercentage: string;
  secondLabel: string;
  secondPercentage: string;
  thirdLabel: string;
  thirdPercentage: string;
}

export const Stats = ({
  firstLabel,
  firstPercentage,
  secondLabel,
  secondPercentage,
  thirdLabel,
  thirdPercentage,
}: StatsProps) => {
  const stats: StatItem[] = [
    { label: firstLabel, percentage: firstPercentage },
    { label: secondLabel, percentage: secondPercentage },
    { label: thirdLabel, percentage: thirdPercentage },
  ];

  return (
    <ul className={styles.stats}>
      {stats.map(({ label, percentage }) => (
        <li key={label} className={styles.item}>
          <p className={styles.percentage}>{percentage}</p>
          <p className={styles.label}>{label}</p>
        </li>
      ))}
    </ul>
  );
};
