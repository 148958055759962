import { Heading } from '@/components/commonComponents/heading/heading';
import { Container } from '@/components/pageComponents/container/container';
import AutoScroll from 'embla-carousel-auto-scroll';
import useEmblaCarousel from 'embla-carousel-react';
import { ReactElement } from 'react';

import styles from './emblaCarousel.module.scss';

interface EmblaCarouselProps {
  title?: string;
  items: ReactElement[];
}

export function EmblaCarousel({ title, items }: EmblaCarouselProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true, dragFree: true },
    [AutoScroll({ playOnInit: true, stopOnMouseEnter: true, speed: 0.5 })]
  );

  const startAutoplay = () => {
    emblaApi?.plugins()?.autoScroll.play();
  };

  return (
    <>
      {title && (
        <Container size="narrow">
          <Heading level="h2" style="regular" tag="h2">
            {title}
          </Heading>
        </Container>
      )}
      <div className={styles.embla} ref={emblaRef} onMouseLeave={startAutoplay}>
        <div className={styles.embla__container}>
          {items.map((item, index) => (
            <div key={index} className={styles.embla__slide}>
              {item}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
