'use client';
import { Heading } from '@/components/commonComponents/heading/heading';
import { Tile } from '@/components/commonComponents/tile/tile';
import { Container } from '@/components/pageComponents/container/container';

import { PrimaryButton } from 'components/buttons';

import { TextBlock } from '../textBlock/textBlock';
import styles from './tileList.module.scss';
import { TileProps } from '@/types/tileProps';
import { ButtonType } from '@/types/misc';

export interface TileListProps extends ButtonType {
  title?: string;
  copy?: string;
  footer?: string;
  tiles: Omit<TileProps, 'orientation'>[];
  maxPerRow?: 2 | 3;
  orientation?: 'vertical' | 'horizontal';
  aspect?: 'square' | 'tall';
}

export const TileList = ({
  title,
  copy,
  footer,
  buttonLink,
  buttonLabel,
  tiles = [],
  maxPerRow = 2,
  orientation = 'vertical',
  aspect,
}: TileListProps) => {
  const getLayout = () => {
    if (orientation === 'horizontal') {
      return 'jobs';
    }
    if (maxPerRow === 2) {
      return 'list';
    }
    return 'tiles';
  };

  const getSize = () => {
    if (orientation === 'horizontal' || maxPerRow === 3) {
      return 'xWide';
    }
    return 'wide';
  };

  const getOrientation = (index: number) => {
    if (orientation === 'vertical' || maxPerRow === 2) {
      return 'vertical';
    }
    return index % 2 ? 'ltr' : 'rtl';
  };

  return (
    <>
      {(title || copy) &&
        (copy ? (
          <TextBlock title={title} copy={copy} />
        ) : (
          <Container size="narrow">
            <Heading level="h2" style="regular" tag="h2">
              {title}
            </Heading>
          </Container>
        ))}
      <Container
        className={`${styles.tileList} ${
          tiles.length === 1 ? styles.centerTile : ''
        }`}
        layoutBlock={getLayout()}
        size={getSize()}
      >
        {tiles.map((tile, index) => {
          return (
            <Tile
              key={`${tile.title}-${tile.id}`}
              {...tile}
              aspect={aspect}
              orientation={getOrientation(index)}
            />
          );
        })}
      </Container>
      {(footer || (buttonLabel && buttonLink)) && (
        <Container size="narrow" layoutBlock="centered">
          {footer && (
            <Heading tag="h3" style="compact" level="h3">
              {footer}
            </Heading>
          )}
          {buttonLabel && buttonLink && (
            <PrimaryButton href={buttonLink}>{buttonLabel}</PrimaryButton>
          )}
        </Container>
      )}
    </>
  );
};
