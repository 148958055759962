import Image from 'next/image';
import { useRef, useState } from 'react';

import { IconButton } from '../../commonComponents/iconButton/iconButton';
import { Icon } from '../icon';
import styles from './videoPlayer.module.scss';

interface VideoPlayerProps {
  className?: string;
  source: string;
  thumbnail: string;
}

export const VideoPlayer = ({
  className,
  source,
  thumbnail,
}: VideoPlayerProps) => {
  const [startedPlaying, setStartedPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const play = () => {
    videoRef.current?.play();
    setStartedPlaying(true);
  };

  return (
    <div className={`${styles.videoPlayer} ${className ?? ''}`}>
      <video
        ref={videoRef}
        className={styles.video}
        src={source}
        poster={thumbnail}
        controls={true}
      />
      {!startedPlaying && (
        <div className={styles.poster} onClick={play}>
          <Image
            className={styles.thumbnail}
            src={thumbnail}
            alt="poster"
            fill
          />
          <IconButton
            className={styles.playButton}
            style="secondary"
            surface="inverted"
            shape="circle"
            onClick={play}
            icon="Play"
            aria-label="Afspelen"
          />
          <Icon className={styles.logo} name="Logo" />
        </div>
      )}
    </div>
  );
};
