import { usePagination } from 'hooks/usePagination';

import { IconButton } from '../../commonComponents/iconButton/iconButton';
import styles from './pagination.module.scss';

interface PaginationProps {
  pageCount: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const boundaryCount = 1;
const siblingCount = 1;

export const Pagination = ({
  pageCount,
  currentPage,
  setCurrentPage,
}: PaginationProps) => {
  const items = usePagination(
    pageCount,
    currentPage,
    boundaryCount,
    siblingCount
  );

  return (
    <nav className={styles.pagination}>
      <IconButton
        icon="ArrowLeft"
        style="tertiary"
        shape="circle"
        onClick={() => {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
          }
        }}
        aria-label="Vorige pagina"
      />
      <p className={styles.pageCount}>
        <span className={styles.currentPage}>{currentPage}</span> / {pageCount}
      </p>
      <ul className={styles.itemList}>
        {items.map((item) => (
          <li key={item}>
            {typeof item === 'number' ? (
              <IconButton
                style={item === currentPage ? 'secondary' : 'tertiary'}
                ghost={item !== currentPage}
                onClick={() => {
                  if (item !== currentPage) {
                    setCurrentPage(item);
                  }
                }}
              >
                {item}
              </IconButton>
            ) : (
              <IconButton style="tertiary" ghost className={styles.ellipsis}>
                ...
              </IconButton>
            )}
          </li>
        ))}
      </ul>
      <IconButton
        icon="ArrowRight"
        style="tertiary"
        shape="circle"
        onClick={() => {
          if (currentPage < pageCount) {
            setCurrentPage(currentPage + 1);
          }
        }}
        aria-label="Volgende pagina"
      />
    </nav>
  );
};
