'use client';
import { LayoutContainer } from 'components/layout-container';

import {
  Testimonial,
  TestimonialProps,
} from '../../commonComponents/testimonial/testimonial';
import { TextBlock } from '../textBlock/textBlock';
import styles from './testimonialCarousel.module.scss';

export interface TestimonialCarouselProps {
  title: string;
  copy: string;
  testimonials: TestimonialProps[];
  buttonLabel?: string;
  buttonLink?: string;
}

export const TestimonialCarousel = ({
  title,
  copy,
  testimonials,
  buttonLabel,
  buttonLink,
}: TestimonialCarouselProps) => {
  return (
    <LayoutContainer size="medium" className={styles.container}>
      <div className={styles.header}>
        <TextBlock
          title={title}
          copy={copy}
          buttonLabel={buttonLabel}
          buttonLink={buttonLink}
          buttonStyle="secondary"
          contained={false}
        />
      </div>
      <div className={styles.scollContent}>
        {testimonials.map((testimonial) => (
          <Testimonial key={testimonial.name} {...testimonial} />
        ))}
      </div>
    </LayoutContainer>
  );
};
