'use client';
import Image from 'next/image';

import { Paragraph } from '../../commonComponents/paragraph/paragraph';
import { EmblaCarousel } from './emblaCarousel';
import styles from './peopleCarousel.module.scss';

type Person = {
  name: string;
  function: string;
  quote: string;
  image: string;
};

export interface PeopleCarouselProps {
  title: string;
  people: Person[];
}

export const PeopleCarousel = ({ title, people }: PeopleCarouselProps) => {
  const personTile = (person: Person) => (
    <article className={styles.personTile} key={person.name}>
      <div className={styles.image}>
        <Image src={person.image} alt={person.name} fill />
      </div>
      <figure className={styles.content}>
        <blockquote className={styles.quote}>{person.quote}</blockquote>
        <figcaption className={styles.person}>
          <Paragraph className={styles.name} style="regular">
            {`${person.name}, ${person.function}`}
          </Paragraph>
        </figcaption>
      </figure>
    </article>
  );

  return <EmblaCarousel title={title} items={people.map(personTile)} />;
};
