'use client';
import { ContactCard, ContactCardProps } from 'components/contact-card';
import { LayoutContainer } from 'components/layout-container';
import { Typography } from 'components/typography';

import styles from './styles.module.scss';

type Person = {
  name: string;
  image: string;
  email: string;
  phone: string;
  functionTitle: string;
};

export type ContactInfoBlockProps = {
  title: string;
  people: Person[];
  general: ContactCardProps[];
};

export const ContactInfoBlock = ({
  title,
  people,
  general,
}: ContactInfoBlockProps) => {
  return (
    <LayoutContainer className={styles.getInTouchContainer} size="medium">
      <Typography variant="heading2-compact" tag="h3">
        {title}
      </Typography>
      <div className={styles.contactCardGrid}>
        <div className={styles.people}>
          {people.map((person, index) => (
            <ContactCard
              key={index}
              image={{
                src: person.image,
                alt: person.name,
              }}
              heading={index === 0 ? 'New business' : 'Werken bij [code]capi'}
              subHeading={`${person.name},\n${person.functionTitle}`}
              actions={[
                {
                  leadingIcon: 'Email',
                  label: person.email,
                  href: `mailto:${person.email}`,
                },
                {
                  leadingIcon: 'Phone',
                  label: person.phone,
                  href: `tel:${person.phone}`,
                },
              ]}
            />
          ))}
        </div>
        <div className={styles.general}>
          {general.map((general, index) => (
            <ContactCard key={index} {...general} />
          ))}
        </div>
      </div>
    </LayoutContainer>
  );
};
