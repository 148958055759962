'use client';
import Image from 'next/image';

import { Carousel } from './carousel';
import styles from './picturesCarousel.module.scss';

type Image = {
  name: string;
  src: string;
};

export interface PicturesCarouselProps {
  title: string;
  copy: string;
  copyLocation?: 'top' | 'bottom';
  images: Image[];
}

export const PicturesCarousel = ({
  title,
  copy,
  copyLocation,
  images,
}: PicturesCarouselProps) => (
  <Carousel
    title={title}
    copy={copy}
    copyLocation={copyLocation}
    showControls
    items={images.map((image) => (
      <div key={image.name} className={styles.imageContainer}>
        <Image
          src={image.src}
          alt={image.name}
          className={styles.image}
          width="1000"
          height="480"
          priority
        />
      </div>
    ))}
  />
);
