import { SingleMediaResponse } from '@/types/strapiBasicResponseProps';
import styles from '@/components/selfContainedComponents/mediaEmbed/mediaEmbed.module.scss';
import Image from 'next/image';
import { VideoPlayer } from '@/components/internalComponents/videoPlayer/videoPlayer';

export const MediaItem = ({
  sourceLink,
  mediaType,
  media,
  thumbnail,
}: {
  sourceLink?: string;
  mediaType: 'portrait' | 'landscape' | 'spotify' | 'fit';
  media?: SingleMediaResponse;
  thumbnail?: SingleMediaResponse;
}) => {
  const imgExtensions = ['jpg', 'jpeg', 'gif', 'png', 'webp', 'bmp'];
  const isImage =
    media?.data &&
    imgExtensions.some((ext: string) =>
      media.data.attributes.url?.endsWith(`.${ext}`)
    );

  const isYoutube = sourceLink?.includes('youtu');

  if (isYoutube) {
    const videoId = sourceLink?.match(/(\?v=|be\/)(.+)/)?.[2];
    return (
      <iframe
        className={`${styles.mediaVideo} ${styles[mediaType]}`}
        src={`https://www.youtube-nocookie.com/embed/${videoId}`}
        title="YouTube video player"
        allow="accelerometer; encrypted-media; gyroscope"
        allowFullScreen
      />
    );
  }

  if (isImage) {
    return (
      <div className={`${styles.mediaImage} ${styles[mediaType]}`}>
        <Image
          src={media.data.attributes.url}
          alt={media.data.attributes.alternativeText as string}
          fill
        />
      </div>
    );
  }

  if (media?.data) {
    return (
      <VideoPlayer
        className={`${styles.mediaVideo} ${styles[mediaType]}`}
        source={media.data.attributes.url}
        thumbnail={thumbnail?.data.attributes.url ?? ''}
      />
    );
  }
  return null;
};
