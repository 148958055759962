import classNames from 'classnames';
import { ReactNode } from 'react';

import { LayoutContainer } from 'components/layout-container';

import styles from './styles.module.scss';

export type MarqueeProps = {
  className?: string;
  itemClassName?: string;
  children: ReactNode[];
};

export type MarqueeContentProps = MarqueeProps & {
  ariaHidden?: boolean;
};

function MarqueeContent({
  itemClassName,
  children,
  ariaHidden = false,
}: MarqueeContentProps): JSX.Element {
  return (
    <ul className={styles.marqueeContent} aria-hidden={ariaHidden}>
      {children.map((item, i) => (
        <li className={itemClassName} key={i}>
          {item}
        </li>
      ))}
    </ul>
  );
}

export function Marquee({ className, itemClassName, children }: MarqueeProps) {
  return (
    <LayoutContainer
      size="large"
      className={classNames(styles.marquee, className)}
    >
      <MarqueeContent itemClassName={itemClassName}>{children}</MarqueeContent>
      <MarqueeContent itemClassName={itemClassName} ariaHidden>
        {children}
      </MarqueeContent>
    </LayoutContainer>
  );
}
