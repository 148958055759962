import { ClientCard } from 'components/client-card';
import { LayoutContainer } from 'components/layout-container';

import { Clienttype } from '@/components/selfContainedComponents/clients/clients';
import styles from './styles.module.scss';

export type ClientGridProps = {
  clients: Clienttype[];
};

export function ClientsGrid({ clients }: ClientGridProps) {
  return (
    <LayoutContainer size="large">
      <ul className={styles.grid}>
        {clients.map((client) => (
          <li key={client.name} className={styles.item}>
            <ClientCard {...client} />
          </li>
        ))}
      </ul>
    </LayoutContainer>
  );
}
