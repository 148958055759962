'use client';
import { ClientsGrid } from 'components/clients-grid';
import { ClientsMarquee } from 'components/clients-marquee';

export type Clienttype = {
  name: string;
  jobTitle: string;
  img: string;
};

export interface ClientsProps {
  clients: Clienttype[];
  display: 'row' | 'grid';
}

export const Clients = ({ clients, display }: ClientsProps) => {
  return display === 'grid' ? (
    <ClientsGrid clients={clients}></ClientsGrid>
  ) : (
    <ClientsMarquee clients={clients}></ClientsMarquee>
  );
};
