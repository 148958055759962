import { TextBlock } from '@/components/selfContainedComponents/textBlock/textBlock';
import { Heading } from '@/components/commonComponents/heading/heading';

export const MediaTextBlock = ({
  copy,
  title,
  buttonLabel,
  buttonLink,
}: {
  copy?: string;
  title?: string;
  buttonLabel?: string;
  buttonLink?: string;
}) => {
  return copy ? (
    <TextBlock
      contained={false}
      title={title}
      copy={`${copy}`}
      buttonLabel={buttonLabel}
      buttonLink={buttonLink}
    />
  ) : (
    <Heading style="regular" level="h2" tag="h2">
      {title}
    </Heading>
  );
};
