'use client';
import Image from 'next/image';

import { Button } from 'components/buttons';

import { Author } from '../../commonComponents/author/author';
import { Heading } from '../../commonComponents/heading/heading';
import { Paragraph } from '../../commonComponents/paragraph/paragraph';
import { Container } from '../../pageComponents/container/container';
import styles from './showCase.module.scss';
import { NamedImageType } from '@/types/misc';

export interface ShowCaseProps {
  title: string;
  authors: NamedImageType[];
  description: string;
  href: string;
  image: string;
}

export const ShowCase = ({
  title,
  authors,
  description,
  href,
  image,
}: ShowCaseProps) => {
  return (
    <Container size="xWide">
      <article className={styles.showCase}>
        <div className={styles.image}>
          <Image fill src={image} alt={title} />
        </div>
        <div className={styles.content}>
          <Heading level="h2" style="sub" tag="h3" className={styles.case}>
            Bazen case
          </Heading>
          <Heading level="h2" style="compact" tag="h2" className={styles.title}>
            {title}
          </Heading>
          {authors.length > 0 && (
            <Author style="fancy" authors={authors} title="Made with love by" />
          )}
          <Paragraph style="regular" className={styles.description}>
            {description}
          </Paragraph>
          <div className={styles.button}>
            <Button
              style={{ type: 'primary' }}
              href={href}
              icon={{ trailing: 'ArrowRight' }}
            >
              Case bekijken
            </Button>
          </div>
        </div>
      </article>
    </Container>
  );
};
